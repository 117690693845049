import './App.css';
import Spline from '@splinetool/react-spline';

function App() {
  return (
      <div>
          <Spline className="mango" scene="https://prod.spline.design/0tVBazrIE4mkExVJ/scene.splinecode" />
        <p className="subheading">Neue Maßstäbe in Sachen 3D und UI/UX-Design</p>
      </div>
  );
}

export default App;